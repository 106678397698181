import { Dispatch } from 'react'

export const setMock = (setLoading: Dispatch<boolean>, setRequest: Dispatch<string[]>, setResult: Dispatch<[]>) => {
    setLoading(true)
    setRequest([
        '02',
        '01',
        '02',
        '02',
        '01',
        '',
        '54',
        '01',
        '',
        '40',
        '01',
        '',
        '01',
        '02',
        '',
        '03',
        'CEJXD',
        '',
        '13',
        '02',
        '01',
        '04',
        '05',
        '',
        '06',
        'BKKPG',
        '',
        '04',
        '01',
        '',
    ])
    setResult([])
    setLoading(false)
}