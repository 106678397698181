import { Request } from '../types'
import { parseRequest } from './'

export function isValid(req: Request): boolean {
  const request = parseRequest(req)

  let filledPairsCount = 0
  request.forEach(pair => {
    if (pair[0].length > 1 && pair[1].length > 1) {
      const regExp = /^[0-9][0-9][0-9]?$/
      if (regExp.test(pair[0][0]) && regExp.test(pair[1][0])) {
        filledPairsCount++
      }
    }
  })
  if (filledPairsCount >= 4) {
    return true
  }
  return false
}
