import React from 'react'

interface Props {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ClientType = (props: Props) => {
    const { changeHandler } = props
    return (
        <div className="client-type-select" onChange={changeHandler}>
            <div className="form-group">
                <label htmlFor="clientType1">Вы сотрудник медицинского учреждения</label>
                <input id="clientType1" type="radio" value="doctor" name="clientType" />
            </div>
            <div className="form-group">
                <label htmlFor="clientType2">Вы пациент/родственник пациента</label>
                <input id="clientType2" type="radio" value="patient" name="clientType" />
            </div>
        </div>
    )
}

export default ClientType