import { Row, Pair, Request, Cell } from '../types'
import _ from 'lodash'

export function process(request: Request, data: Request[]) {
  const newResult = [
    { pairs: 5, oneCells: 0, count: 0 },
    { pairs: 4, oneCells: 0, count: 0 },
    { pairs: 4, oneCells: 1, count: 0 },
    { pairs: 3, oneCells: 1, count: 0 },
  ]
  for (let i = 1; i < data.length; i++) {
    const row = data[i]
    const { pairs, oneCells } = countPairs(request, row)

    if (pairs == 5 && oneCells == 0) {
      const res = newResult.find(r => r.pairs == 5 && r.oneCells == 0)
      res && res.count++
    }

    if (pairs == 4 && oneCells == 0) {
      const res = newResult.find(r => r.pairs == 4 && r.oneCells == 0)
      res && res.count++
    }

    if (pairs == 4 && oneCells == 1) {
      const res = newResult.find(r => r.pairs == 4 && r.oneCells == 1)
      res && res.count++
    }

    if (pairs == 3 && oneCells == 1) {
      const res = newResult.find(r => r.pairs == 3 && r.oneCells == 1)
      res && res.count++
    }
  }

  return newResult
}

function countPairs(request: Request, row: Request) {
  let rowArray
  if (typeof row === 'object') {
    let newArray = []
    for (const property in row) {
      newArray.push(row[property])
    }
    rowArray = newArray
  } else {
    rowArray = row
  }
  const rq = parseRequest(request)
  const rw = parseRow(rowArray)
  let count = { pairs: 0, oneCells: 0 }
  for (let i = 0; i < rq.length; i++) {
    if (isSamePair(rq[i], rw[i])) {
      count.pairs = count.pairs + 1
    } else {
      if (hasOneCellEquals(rq[i], rw[i])) {
        count.oneCells = count.oneCells + 1
      }
    }
  }
  return count
}

function hasOneCellEquals(pair1: Pair, pair2: Pair) {
  // console.log('Compare pairs:: pair1 :: ', pair1, ' pair2 :: ', pair2)

  if (isCellsEqual(pair1[0], pair2[0]) || isCellsEqual(pair1[0], pair2[1])) {
    return true
  }

  if (isCellsEqual(pair1[1], pair2[0]) || isCellsEqual(pair1[1], pair2[1])) {
    return true
  }

  return false
}

function isSamePair(pair1: Pair, pair2: Pair) {
  let pairs = [pair1, pair2]

  //Remove all G from pairs to compare without it
  //And transfor each triplet to lower case for case insensitive comparison

  let newPairs: any[] = []

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i]
    newPairs.push([])
    for (let z = 0; z < pair.length; z++) {
      newPairs[i].push([])
      const cell = pair[z]
      for (let x = 0; x < cell.length; x++) {
        newPairs[i][z].push(cell[x].toLowerCase().replace('g', ''))
      }
    }
  }

  const p1 = newPairs[0]
  const p2 = newPairs[1]

  //Check if pairs length is not same, dont waste more time and return false
  // console.log('Compare pairs:: pair1 :: ', p1, ' pair2 :: ', p2)

  if (p1.length !== p2.length) {
    return false
  }

  if (_.isEqual(p1, p2)) {
    return true
  }

  if (isCellsEqual(p1[0], p2[1]) && isCellsEqual(p1[1], p2[0])) {
    return true
  }

  if (isCellsEqual(p1[0], p2[0]) && isCellsEqual(p1[1], p2[1])) {
    return true
  }

  return false
}

function isCellsEqual(c1: Cell, c2: Cell) {
  if (c1.length < 3 && c2.length > 2) {
    c2.pop()
  }
  return _.isEqual(c1, c2)
}

export function parseRequest(request: Request): Row {
  const cells = _.chunk(request, 3) as Cell[]
  cells.forEach(cell => {
    if (cell[2] == '') {
      cell.pop()
    }
  })
  const pairs = _.chunk(cells, 2) as Pair[]
  const row: Row = pairs
  return row
}

function parseRow(r: string[]): Row {
  let rows: any = []
  r.forEach(e => {
    const cell = e.split(':') as Cell
    rows.push(cell)
  })
  const row = _.chunk(rows, 2) as Row
  return row
}
