import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            // console.log('response.data.message')
        }
        return response
    },
    error => {
        // if (error.response && error.response.data.message) {
        //     const errors = error.response.data.errors
        //     if (errors) {
        //         for (var _key of Object.keys(errors)) {
        //             // toast.error(key + ':' + errors[key])
        //         }
        //     }
        // }
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
        if (!expectedError) {
            // logger.log(error)
            // toast.error(error.message)
        }
        if (error.response && error.response.status === 401) {
            // toast.error('Not authorized')
            // logout('token')
            // window.location = '/auth/signin'
        }
        if (error.response && error.response.status === 403) {
            // toast.error('Access denied')
            // window.location = '/denied'
        }
        if (error.response && error.response.status === 404) {
            // toast.error('404: Not Found')
            // window.location = '/notfound'
        }
        if (error.response && error.response.status === 500) {
            // toast.error('Server error')
            // logger.log(error)
            // window.location = '/server-error'
        }
        return Promise.reject(error)
    }
)

export const post = axios.post
export const get = axios.get
export const put = axios.put
export const del = axios.delete

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}

export default http
