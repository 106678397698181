import React, { useState, useEffect } from 'react'
import SearchForm from '../searchForm'
import { ClientType, ErrorResponse, Request, Result } from '../../types'
import { process as calc, isValid } from '../../services'
import { setMock } from '../../utils/mock'
import { getDnaData } from '../../api/dna'
import { logSubmit } from '../../api/logSubmit'

const HomePage = () => {
  const [rows, setRows] = useState<Request[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [request, setRequest] = useState<Request>([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ])
  const [result, setResult] = useState<Result[]>([])
  const [showError, setShowError] = useState(false)
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const [clientType, setClientType] = useState<ClientType>()
  const [error, setError] = useState<ErrorResponse>()


  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    rows.length && setLoading(false)
    result.length && setLoading(false)
  }, [rows, result])

  useEffect(() => {
    setShowError(false)
  }, [request])

  const submitHandler = () => {
    if (isValid(request)) {
      clientType && logSubmit(clientType)
      setLoading(true)
      const res = calc(request, rows) as []
      setResult(res)
    } else {
      setShowError(true)
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const inputIndex = parseInt(e.target.id)
    const newRequest = [...request]
    newRequest[inputIndex] = e.target.value
    setRequest(newRequest)
  }

  const fetchData = () => {
    setError(undefined)
    getDnaData().then(res => {
      if (res && !res.data.success) {
        const errRes = res.data as ErrorResponse
        setError(errRes)
        setLoading(false)
        return
      }
      setRows(res.data.data)
      setLoading(false)
    })
  }

  const newSearch = () => {
    setLoading(true)
    setRequest([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ])
    setResult([])
    setLoading(false)
    console.clear()
  }

  const checkRecaptchaToken = async (token: string) => {
    fetch('https://dna.bmdonego.ru/api/recaptcha/validate', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
      .then(response => response.json())
      .then(data => {
        setCaptchaPassed(data.success)
      })
  }

  function getNoun(number: number, one: string, two: string, five: string) {
    let n = Math.abs(number)
    n %= 100
    if (n >= 5 && n <= 20) {
      return five
    }
    n %= 10
    if (n === 1) {
      return one
    }
    if (n >= 2 && n <= 4) {
      return two
    }
    return five
  }




  return !loading ? (
    <div className="app">
      {error && <ErrorMessage error={error} />}
      {rows.length > 0 && result.length <= 0 && (
        <>
          <span className="app__info">Введите данные для поиска</span>
          {showError && (
            <span className="app__info__error">
              Ошибка заполнения формы, запрос не может быть выполнен.
            </span>
          )}
          <SearchForm
            data={request}
            changed={onInputChange}
            submitted={submitHandler}
            recaptchaChanged={checkRecaptchaToken}
            captchaPassed={captchaPassed}
            setClientType={setClientType}
            clientType={clientType}
          />
          {process.env.REACT_APP_ENV === 'development' &&
            <button
              onClick={() => setMock(setLoading, setRequest, setResult)}
              className="button request-form__submit"
            >
              Заполнить форму (Тест)
            </button>}
          {/* <button
            onClick={() => setRows([])}
            className="button request-form__submit"
          >
            Другой файл
          </button> */}
        </>
      )}
      {result.length > 0 && (
        <div className="app__result">
          <span className="app__result__header">Результат:</span>
          {result &&
            result.map((r, i) => {
              return (
                <p key={i} className="app__result__row">
                  {r.pairs} {getNoun(r.pairs, 'пара', 'пары', 'пар')}{' '}
                  {r.oneCells > 0 && `и одна ячейка`}, совпадений: {r.count}
                </p>
              )
            })}
          <div onClick={newSearch} className="button">
            Новый поиск
          </div>
        </div>
      )}
    </div>
  ) : (
    <Preloader />
  )
}

export default HomePage

const Preloader = () => (
  <div className="flex flex-centered fullscreen">
    <h2 className="preloader">Загрузка...</h2>
  </div>
)

interface ErrorMessageProps {
  error: ErrorResponse
}

const ErrorMessage = (props: ErrorMessageProps) => (
  <div className="flex flex-centered fullscreen" >
    <h2 className="error-message">Ошибка: {props.error && props.error.msg}</h2>
  </div >
)