import _ from 'lodash'
import { Dispatch } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { ClientType } from '../../types'
import ClientTypeSelect from '../clientType'


interface Props {
  changed: any
  data: string[]
  submitted: () => void
  recaptchaChanged: any
  captchaPassed: boolean,
  setClientType: Dispatch<ClientType>,
  clientType: ClientType
}

const SearchForm = (props: Props) => {
  const { changed, data, submitted, recaptchaChanged, captchaPassed, setClientType, clientType } = props

  const cells = _.chunk(data, 3)
  const pairs = _.chunk(cells, 2)
  let inputsCount = -1
  let cellsCount = -1
  const labels = [
    'A1',
    'A2',
    'B1',
    'B2',
    'C1',
    'C2',
    'DRB1_1',
    'DRB1_2',
    'DQB1_1',
    'DQB1_2',
  ]

  function clientTypeChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const typeOfClient = e.target.value as ClientType
    setClientType(typeOfClient)
  }

  return (
    <>
      <div className="request-form">
        {pairs.map((pair, i) => (
          <div key={i} className="request-form__pair">
            {pair.map((cell, i) => {
              cellsCount = cellsCount + 1
              return (
                <div key={i} className="request-form__cell">
                  <span className="request-form__input__label">
                    {labels[cellsCount]}
                  </span>
                  <div className="request-form__cell__inputs">
                    {cell.map((triplet, z) => {
                      inputsCount = inputsCount + 1
                      const id = inputsCount.toString()

                      return (
                        <span key={z} className="request-form__input-group">
                          <input
                            id={id}
                            key={inputsCount}
                            type="text"
                            className="request-form__input"
                            value={triplet}
                            onChange={e => changed(e)}
                          />
                          {z !== 2 && (
                            <span className="request-form__input__dots">
                              :
                            </span>
                          )}
                        </span>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        ))}

        <ClientTypeSelect changeHandler={clientTypeChangeHandler} />

        {!captchaPassed && <p>Пожалуйста, подтвердите, что вы не робот.</p>}

        {captchaPassed && clientType ? (
          <button className="button request-form__submit" onClick={submitted}>
            Поиск
          </button>
        ) : (
          <ReCAPTCHA
            sitekey="6LdCibAaAAAAAL9QRu0GNj0F7kRl3mv-x8XxJt55"
            onChange={recaptchaChanged}
            type="image"
            hl={'ru'}
          />
        )}
      </div>
    </>
  )
}

export default SearchForm
