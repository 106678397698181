import { ClientType } from '../types'
import http from './httpService'


export async function logSubmit(clientType: ClientType) {
    try {
        http.post('/submits-log', { clientType })
    } catch (error) {
        console.log('Log submit error ', error)
    }
}

